/** @jsx jsx */
import { useStaticQuery, graphql } from 'gatsby'
import { jsx } from 'theme-ui'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import DescriptionList from '../components/descriptionList'

const PhotosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allPhotos: allFile(filter: { sourceInstanceName: { eq: "photoshoot" } }) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              aspectRatio
              originalImg
              originalName
            }
          }
        }
      }
    }
  `)

  data.allPhotos.nodes.sort((a, b) => {
    return (
      parseInt(a.childImageSharp.fluid.originalName, 10) -
      parseInt(b.childImageSharp.fluid.originalName, 10)
    )
  })

  const curatePlacement = (photo) => {
    let gridColumnStart
    let gridRowStart
    const photoNumber = photo.originalName.split('.')[0]

    if (photoNumber === '34') {
      gridColumnStart = '1'
      gridRowStart = '1'
    }

    if (photoNumber === '66') {
      gridColumnStart = ['2', '2', '2', '3']
      gridRowStart = '5'
    }

    if (photoNumber === '82') {
      gridColumnStart = '1'
      gridRowStart = '8'
    }

    if (photoNumber === '84') {
      gridColumnStart = ['2', '2', '2', '3']
      gridRowStart = '10'
    }

    return {
      gridColumnStart,
      gridRowStart,
    }
  }

  return (
    <Layout>
      <SEO title="Our Engagement" />
      <DescriptionList
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: ['column', 'row'],
          mt: 4,
          mb: [0, 3],
          '> span:not(:last-child)': {
            mr: [4, 5],
          },
          dd: {
            fontSize: 3,
          },
        }}
      >
        <span>
          <dt>photographer</dt>
          <dd>
            <a
              href="https://www.kristinaadamsphotography.com/"
              sx={{
                variant: 'buttons.text',
                fontWeight: 200,
              }}
            >
              <span>Kristina Adams</span>
            </a>
          </dd>
        </span>
        <span>
          <dt>location</dt>
          <dd>
            <a
              href="https://goo.gl/maps/RAJ7HLTSnhE4khoL6"
              sx={{
                variant: 'buttons.text',
                fontWeight: 200,
              }}
            >
              <span>Thomas F. Riley Wilderness Park</span>
            </a>
          </dd>
        </span>
      </DescriptionList>
      <ul
        sx={{
          display: 'grid',
          gridGap: '1px',
          gridTemplateColumns: [
            '1fr 1fr',
            'repeat(auto-fit, minmax(260px, 1fr))',
            'repeat(auto-fit, minmax(310px, 1fr))',
          ],
          mb: 3,
        }}
      >
        {data.allPhotos.nodes.map(({ childImageSharp: { fluid: photo } }) => {
          return (
            <li
              key={photo.originalName}
              sx={{
                ...curatePlacement(photo),
                gridRowEnd: `span ${photo.aspectRatio < 1 ? '2' : '1'}`,
                boxShadow: (theme) => `0 0 0 1px ${theme.colors.gray['400']}`,
                '::before': {
                  content: '""',
                },
              }}
            >
              <a
                href={photo.originalImg}
                sx={{
                  ' picture': {
                    transition: 'transform .3s cubic-bezier(.5, 0, .5, 1)',
                    transform: 'scale(1)',
                  },
                  ':hover picture, :focus picture': {
                    transform: 'scale(1.03)',
                  },
                }}
              >
                <Img
                  fluid={photo}
                  imgStyle={{
                    display: 'block',
                    gridArea: 'img',
                    position: 'static',
                    height: 'auto',
                  }}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridTemplateAreas: '"img"',
                    justifyContent: 'center',
                    height: 0,
                    paddingBottom: photo.aspectRatio < 1 ? '129%' : '63%',
                    border: '4px solid white',
                    ' > div': { display: 'none' },
                    picture: {
                      gridArea: 'img',
                      width: 'auto',
                    },
                  }}
                />
              </a>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default PhotosPage
